<template>
  <div>
    <!-- 通过率/达标率 -->
    <Echart
      :options="options"
      :id="id"
      :height="height"
      :width="width"
    ></Echart>
  </div>
</template>

<script>
import Echart from "@/common/echart";
export default {
  data() {
    return {
      options: {},
    };
  },
  components: {
    Echart,
  },
  props: {
    id: {
      type: String,
      required: true,
      default: "chartRate",
    },
    tips: {
      type: Number,
      required: true,
      default: 50,
    },
    text: {
      type: String,
      required: false,
      default: "",
    },
    unit: {
      type: String,
      required: false,
      default: "%",
    },
    height: {
      type: String,
      required: false,
      default: "2.05rem",
    },
    width: {
      type: String,
      required: false,
      default: "2.05rem",
    },
    maxValue: {
      type: Number,
      required: true,
      default: 100,
    },
    colorObj: {
      type: Object,
      default: function () {
        return {
          textStyle: "#3fc0fb",
          series: {
            color: ["#00bcd44a", "transparent"],
            dataColor: {
              normal: "#03a9f4",
              shadowColor: "#97e2f5",
            },
          },
        };
      },
    },
  },
  watch: {
    // tips 是会变更的数据，所以进行监听
    tips: {
      handler(newData) {
        this.options = {
          title: {
            zlevel: 0,
            text: [
              "{name|" + this.text + "}",
              "{value|" + newData * 1 + this.unit + "}",
            ].join("\n"),
            top: "35%",
            left: "48%",
            textAlign: "center",
            textStyle: {
              rich: {
                value: {
                  color: "#faf3a378",
                  fontWeight: "bold",
                  fontSize: 30 / 24 + "rem",
                },
                name: {
                  color: "#909399",
                  lineHeight: 20,
                  fontSize: 15 / 24 + "rem",
                },
              },
            },
          },
          color: ["#2FC25B"],
          grid: {
            left: "3%",
            right: "4%",
            bottom: "3%",
            containLabel: true,
          },
          series: [
            {
              type: "pie",
              center: ["50%", "50%"],
              radius: ["50%", "70%"],
              hoverAnimation: false,
              color: this.colorObj.series.color,
              label: {
                normal: {
                  show: false,
                },
              },
              data: [
                {
                  value: newData,
                  itemStyle: {
                    normal: {
                      color: this.colorObj.series.dataColor.normal,
                      shadowBlur: 10,
                      shadowColor: this.colorObj.series.dataColor.shadowColor,
                    },
                  },
                },
                {
                  value: this.maxValue - newData,
                },
              ],
            },
          ],
        };
      },
      immediate: true,
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
</style>