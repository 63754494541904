<template>
  <drag-modal
    :visible="visible"
    :loading="loading"
    width="4.5rem"
    @cancel="() => (visible = false)"
  >
    <div class="flex-row justify-between align-center" slot="title">
      <div class="flex-row flex-1">
        <span
          class="font-bold trans-o-left"
          style="transform: scale(0.931944); font-size: 11.1833px"
          >{{ title }}{{ queryParam.Name }}</span
        >
      </div>
      <div>
        <span
          style="
            padding-right: 13.9792px;
            padding-left: 13.9792px;
            display: inline-block;
            transform: scale(0.815451);
            font-size: 9.78542px;
          "
        >
          今日报警 <span class="font-bold">{{ alarmCount }}</span> 次</span
        >
      </div>
    </div>
    <div>
      <div class="flex-column center" style="font-size: 9.78542px">
        <span v-if="data.WorkStatus == 2" style="color: green">(运行中)</span
        ><span v-else style="color: red">(顶升监控)</span>
        <div style="float: right">采集时间：{{ data.RecordTime }}</div>
      </div>
    </div>
    <div class="flex-column center" style="margin-top: 18.1729px">
      <div class="module flex-row center">
        <div
          class="flex-column center"
          style="margin-right: 6.98958px; width: 25%"
        >
          <span
            class="flex-row align-center"
            style="transform: scale(0.815451); font-size: 9.78542px"
            >安全起重</span
          >
          <span class="flex-row align-center"
            ><span
              class="font-bold"
              style="
                word-break: break-all;
                transform: scale(0.931944);
                font-size: 11.1833px;
              "
              >{{ data.RatedWeight }}</span
            >
            <span
              class="flex-fix"
              style="transform: scale(0.815451); font-size: 9.78542px"
              >t</span
            ></span
          >
        </div>
        <div class="flex-column center" style="width: 50%">
          <div class="flex flex-center combine-circular-container">
            <div
              class="chart module"
              _echarts_instance_="ec_1618972249866"
              style="
                -webkit-tap-highlight-color: transparent;
                user-select: none;
                background: transparent;
              "
            >
              <div
                style="
                  position: relative;
                  overflow: hidden;
                  width: 1.78rem;
                  height: 1.78rem;
                  padding: 0px;
                  margin: 0px;
                  border-width: 0px;
                  cursor: default;
                "
              >
                <PieCicleChart2
                  id="test"
                  :tips="data.Moment"
                  text="力矩"
                  :colorObj="colorData"
                  unit=""
                  :maxValue="1"
                  width="1.78rem"
                  height="1.78rem"
                />
              </div>
            </div>
          </div>
        </div>
        <div
          class="flex-column center"
          style="margin-left: 6.98958px; width: 25%"
        >
          <span
            class="flex-row align-center"
            style="transform: scale(0.815451); font-size: 9.78542px"
            >倍率</span
          >
          <span class="flex-row align-center"
            ><span
              class="font-bold"
              style="
                word-break: break-all;
                transform: scale(0.931944);
                font-size: 11.1833px;
              "
              >{{ data.Multiple }}</span
            >
            <span style="transform: scale(0.815451); font-size: 9.78542px"
              >倍</span
            ></span
          >
        </div>
      </div>
      <ul class="clearfix device-list flex-wrap">
        <li
          class="flex-row"
          style="
            margin-top: 13.9792px;
            padding-left: 13.9792px;
            width: 122.318px;
          "
        >
          <span
            class="
              item-circle
              zhgd_iconfont zhgd_icon-tadiaogou
              flex-row
              center
            "
            style="
              width: 29.3562px;
              height: 29.3562px;
              margin-right: 6.98958px;
              transform: scale(1);
              font-size: 18.1729px;
            "
          ></span>
          <div class="flex-column">
            <span
              class="trans-o-left"
              style="transform: scale(0.815451); font-size: 9.78542px"
              >重量</span
            >
            <span class="flex-row"
              ><span
                class="font-bold trans-o-left"
                style="transform: scale(0.931944); font-size: 11.1833px"
                >{{ data.Weight }}</span
              >
              <span
                class="trans-o-left"
                style="transform: scale(0.815451); font-size: 9.78542px"
                >t</span
              ></span
            >
          </div>
        </li>
        <li
          class="flex-row"
          style="
            margin-top: 13.9792px;
            padding-left: 13.9792px;
            width: 122.318px;
          "
        >
          <span
            class="item-circle zhgd_iconfont zhgd_icon-fudu flex-row center"
            style="
              width: 29.3562px;
              height: 29.3562px;
              margin-right: 6.98958px;
              transform: scale(1);
              font-size: 18.1729px;
            "
          ></span>
          <div class="flex-column">
            <span
              class="trans-o-left"
              style="transform: scale(0.815451); font-size: 9.78542px"
              >幅度</span
            >
            <span class="flex-row"
              ><span
                class="font-bold trans-o-left"
                style="transform: scale(0.931944); font-size: 11.1833px"
                >{{ data.RRange }}</span
              >
              <span
                class="trans-o-left"
                style="transform: scale(0.815451); font-size: 9.78542px"
                >m</span
              ></span
            >
          </div>
        </li>
        <li
          class="flex-row"
          style="
            margin-top: 13.9792px;
            padding-left: 13.9792px;
            width: 122.318px;
          "
        >
          <span
            class="item-circle zhgd_iconfont zhgd_icon-gaodu flex-row center"
            style="
              width: 29.3562px;
              height: 29.3562px;
              margin-right: 6.98958px;
              transform: scale(1);
              font-size: 18.1729px;
            "
          ></span>
          <div class="flex-column">
            <span
              class="trans-o-left"
              style="transform: scale(0.815451); font-size: 9.78542px"
              >高度</span
            >
            <span class="flex-row"
              ><span
                class="font-bold trans-o-left"
                style="transform: scale(0.931944); font-size: 11.1833px"
                >{{ data.Height }}</span
              >
              <span
                class="trans-o-left"
                style="transform: scale(0.815451); font-size: 9.78542px"
                >m</span
              ></span
            >
          </div>
        </li>
        <li
          class="flex-row"
          style="
            margin-top: 13.9792px;
            padding-left: 13.9792px;
            width: 122.318px;
          "
        >
          <span
            class="item-circle zhgd_iconfont zhgd_icon-huizhuan flex-row center"
            style="
              width: 29.3562px;
              height: 29.3562px;
              margin-right: 6.98958px;
              transform: scale(1);
              font-size: 18.1729px;
            "
          ></span>
          <div class="flex-column">
            <span
              class="trans-o-left"
              style="transform: scale(0.815451); font-size: 9.78542px"
              >回转</span
            >
            <span class="flex-row"
              ><span
                class="font-bold trans-o-left"
                style="transform: scale(0.931944); font-size: 11.1833px"
                >{{ data.Angle }}</span
              >
              <span
                class="trans-o-left"
                style="transform: scale(0.815451); font-size: 9.78542px"
                >°</span
              ></span
            >
          </div>
        </li>
        <li
          class="flex-row"
          style="
            margin-top: 13.9792px;
            padding-left: 13.9792px;
            width: 122.318px;
          "
        >
          <span
            class="item-circle zhgd_iconfont zhgd_icon-feng flex-row center"
            style="
              width: 29.3562px;
              height: 29.3562px;
              margin-right: 6.98958px;
              transform: scale(1);
              font-size: 18.1729px;
            "
          ></span>
          <div class="flex-column">
            <span
              class="trans-o-left"
              style="transform: scale(0.815451); font-size: 9.78542px"
              >风速</span
            >
            <span class="flex-row"
              ><span
                class="font-bold trans-o-left"
                style="transform: scale(0.931944); font-size: 11.1833px"
                >{{ data.WindSpeed }}</span
              >
              <span
                class="trans-o-left"
                style="transform: scale(0.815451); font-size: 9.78542px"
                >m/s</span
              ></span
            >
          </div>
        </li>
        <li
          class="flex-row"
          style="
            margin-top: 13.9792px;
            padding-left: 13.9792px;
            width: 122.318px;
          "
        >
          <span
            class="item-circle zhgd_iconfont zhgd_icon-qingjiao flex-row center"
            style="
              width: 29.3562px;
              height: 29.3562px;
              margin-right: 6.98958px;
              transform: scale(1);
              font-size: 18.1729px;
            "
          ></span>
          <div class="flex-column">
            <span
              class="trans-o-left"
              style="transform: scale(0.815451); font-size: 9.78542px"
              >前倾角</span
            >
            <span class="flex-row"
              ><span
                class="trans-o-left"
                style="transform: scale(0.815451); font-size: 9.78542px"
                >X</span
              >
              <span
                class="font-bold trans-o-left"
                style="
                  padding-left: 3.49479px;
                  transform: scale(0.931944);
                  font-size: 11.1833px;
                "
                >{{ data.Obliguity }}</span
              >
              <span
                class="trans-o-left"
                style="transform: scale(0.815451); font-size: 9.78542px"
                >°</span
              ></span
            >
          </div>
        </li>
      </ul>
    </div>
  </drag-modal>
</template>
<script>
import DragModal from "@/components/DragModal";
import PieCicleChart2 from "@/components/echart/center/PieCicleChart2";
export default {
  data() {
    return {
      loading: true,
      visible: false,
      title: "",
      queryParam: {},
      alarmCount: 0,
      data: {},
      colorData: {
        textStyle: "#3fc0fb",
        series: {
          color: ["#00bcd44a", "transparent"],
          dataColor: {
            normal: "#03a9f4",
            shadowColor: "#97e2f5",
          },
        },
      },
    };
  },
  components: {
    DragModal,
    PieCicleChart2,
  },
  methods: {
    openForm(device) {
      this.title = device.DeviceMethodStr;
      this.queryParam = { ProId: device.ProId, DeviceId: device.Id };
      this.loading = false;
      this.visible = true;
      this.init();
    },
    init() {
      console.log(this.queryParam);
      this.loading = true;
      this.$http
        .post("/DeviceManage/DeviceTower/GetFullRecordDataList", {
          PageIndex: 1,
          PageRows: 1,
          SortField: "RecordDate",
          SortType: "DESC",
          Search: this.queryParam,
        })
        .then((resJson) => {
          this.loading = false;
          if (resJson.Data && resJson.Data.length == 1)
            this.data = resJson.Data[0];
          else this.data = {};
        });
    },
  },
};
</script>
<style scoped>
.align-center {
  align-items: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  text-align: center;
}
.device-list li {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  float: left;
}
</style>